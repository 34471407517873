.mainPage {
    background-color: #f2f0f0;
    display: flex;
    position: relative;
    max-height: 1080px;
}

.mainPage .leftBanner {
    margin-left: 0;
    margin-right: auto;
}

.mainPage .content {
    width: 100%;
    padding-top: 135px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.mainPage .content>div:first-child {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.mainPage .mainTitle {
    position: absolute;
    left: 48px;
    top: 150px;
}

.mainPage .pictograms {
    position: absolute;
    bottom: 18px;
    left: 18px;
}

.mainPage .content .prizes {
    margin-left: 89px;
    margin-bottom: 71px;
    margin-top: -224px;
    max-width: calc(100% - 334px);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
}

.mainPage .prizes>img {
    flex-shrink: 1;
    max-width: 100%;
    height: auto;
    min-width: 0;
}

.mainPage .content .carImage {
    margin-top: 53px;
    margin-left: 104px;
    max-width: calc(100% - 434px);
    height: auto;
    flex-shrink: 1;
}

.mainPage .prizes .voucherImage {
    margin-bottom: 32px;
    margin-right: 32px;
}

.mainPage .prizes .fridgeImage {
    margin-right: 30px;
}

.mainPage .prizes .bagImage {
    margin-bottom: 21px;
}

.mainPage .textHolder {
    display: flex;
    flex-direction: column;
    margin-right: 45px;
    margin-top: 41px;
    width: max-content;
}

.mainPage .textHolder .textItem {
    font-family: "Myriad Pro";
    font-weight: 600;
    text-transform: uppercase;
    line-height: 0.99;
    text-align: right;
    margin-bottom: 67px;
}

.mainPage .textHolder .textItem>div:first-child {
    font-size: 29px;
    color: rgb(0, 2, 5);

}

.mainPage .textHolder .textItem>div:last-child {
    color: #da2128;
    font-size: 40.5px;
    text-wrap: nowrap;
}

.mainPage .mobileFooter {
    display: none;
}

.mainPage .desktopIcons {
    position: absolute;
    bottom: 32px;
    right: 46px;
    display: flex;
}

.mainPage .desktopIcons a:first-child {
    margin-right: 8px;
}

.mainPage .desktopIcons a {
    width: 37px;
    height: 37px;
}

.mainPage .desktopIcons img {
    width: 37px;
    height: 37px;
    filter: brightness(0) saturate(100%) invert(0%) sepia(98%) hue-rotate(222deg) contrast(101%);
}

.soonStarting {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 434px);
    height: 100%;
    z-index: 55;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.soonStarting .final-text {
    font-family: "Myriad Pro";
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 53px;
    font-weight: 600;
    font-stretch: condensed;
}

.soonStarting .final-center {
    font-family: "Myriad Pro";
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 114px;
    font-weight: 600;
    font-stretch: condensed;
}

.soonMain {
    line-height: 1.1;
    font-family: "Myriad Pro";
    font-weight: 600;
    color: rgb(255, 255, 255);
    background-color: rgb(213, 12, 20);
    border-radius: 20px;
    width: max-content;
    height: max-content;
    padding: 15px 30px 16px 30px;
    margin-top: 419px;
    margin-bottom: 50px;
}

.soonText {
    font-size: 46px;
    font-family: "Myriad Pro";
    font-weight: 600;
    color: rgb(255, 255, 255);
    line-height: 1.1;
}

.soonTextMobile {
    display: none;
}

.lastTextWelcome {
    font-family: "Myriad Pro";
    color: rgb(213, 12, 20);
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    font-stretch: condensed;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
}

@media (max-width: 1080px) {
    .lastTextWelcome {
        font-size: 36px;
        margin-top: 35px;
        margin-bottom: 50px;
        position: static;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        transform: none;
        margin-top: 0;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
    }
}

@media (max-width: 500px) {
    .lastTextWelcome {
        font-size: 26px;
    }
}

@media (max-width: 1550px) {
    .mainPage .content .carImage {
        margin-left: 45px;
        max-width: calc(100% - 334px);
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 25px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 35px;
    }

    .mainPage .content .prizes {
        margin-left: 45px;
    }

    .soonMain {
        font-size: 153px;
        padding: 15px 30px 16px 30px;
        margin-bottom: 50px;
    }

    .soonText {
        font-size: 34.5px;
    }
}

@media (max-width: 1444px) {
    .mainPage .content .prizes {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: -270px;
    }

    .mainPage .prizes>img:first-child {
        width: 390px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 0;
    }

    .mainPage .prizes .fridgeImage {
        width: 38%;
    }

    .mainPage .prizes .bagImage {
        width: 48%;
    }
}

@media (max-width: 1350px) {
    .mainPage .content>div:first-child {
        flex-direction: column;
    }

    .mainPage .content .carImage {
        order: 2;
        margin-top: 0;
        max-width: 92%;
    }

    .mainPage .textHolder {
        order: 1;
    }

    .mainPage .textHolder .textItem {
        text-align: left;
        margin-left: 45px;
        margin-bottom: 25px;
    }

    .mainPage .content .prizes {
        position: absolute;
        top: 435px;
        right: 0;
        width: 450px;
    }

    .mainPage .prizes>img:first-child {
        width: 350px;
    }

    .mainPage .prizes>img {
        width: 30%;
    }

    .soonStarting .final-text {
        font-size: 40px;
    }

    .soonStarting .final-center {
        font-size: 80px;
    }
}

@media (max-width: 1200px) {
    .mainPage {
        max-height: max-content;
    }

    .mainPage .leftBanner {
        display: none;
    }

    .mainPage .content {
        padding-top: 316px;
    }

    .mainPage .pictograms {
        display: none;
    }

    .mainPage .mobileFooter {
        background: linear-gradient(to right, #990a0f, #ce1219);
        height: 276px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .mainPage .mainTitle {
        display: none;
    }

    .mainPage .mobileMainTitle {
        font-family: "Gilroy";
        color: rgb(255, 255, 255);
        font-weight: 900;
        line-height: 1;
        font-size: 58px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .mainPage .mobilePictograms {
        margin-left: 33px;
        z-index: 5;
    }

    .mainPage .mobileIcons {
        margin-right: 33px;
        display: flex;
        flex-direction: column;
    }

    .mainPage .mobileIcons>a:first-child {
        margin-bottom: 8px;
    }

    .mainPage .content .prizes {
        top: 667px;
        right: 14px;
        width: 700px;
    }

    .mainPage .prizes>img:first-child {
        width: 438px;
    }

    .mainPage .prizes>.fridgeImage {
        width: 240px;
    }

    .mainPage .prizes>.bagImage {
        width: 357px;
        margin-bottom: 0;
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 28px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 45px;
    }

    .mainPage .textHolder .textItem {
        margin-bottom: 67px;
    }

    .mainPage .desktopIcons {
        display: none;
    }

    .soonStarting {
        top: 238px;
        width: 100%;
        height: calc(100% - 514px);
    }

    .soonMain {
        margin-top: 250px;
    }
}

@media (max-width: 1050px) {
    .mainPage .mobileMainTitle {
        font-size: 41px;
    }

    .mainPage .mobilePictograms {
        max-width: 150px;
    }

    .mainPage .mobileFooter {
        height: 240px;
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 25px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 40px;
    }

    .mainPage .content .prizes {
        top: 667px;
    }

    .mainPage .prizes>img:first-child {
        width: 438px;
    }

    .mainPage .prizes>.fridgeImage {
        max-width: 240px;
    }

    .mainPage .prizes>.bagImage {
        max-width: 357px;
        margin-bottom: 0;
    }

    .soonStarting {
        height: calc(100% - 478px);
    }
}

@media (max-width: 1000px) {
    .mainPage .content .prizes {
        top: 667px;
        margin-left: 0;
        max-width: 58%;
    }

    .mainPage .textHolder {
        margin-right: 0;
    }

    .mainPage .prizes>img:first-child {
        width: 438px;
    }

    .mainPage .prizes>.fridgeImage {
        width: 35%;
    }

    .mainPage .prizes>.bagImage {
        width: 51%;
    }
}

@media (max-width: 750px) {
    .mainPage .mobileMainTitle {
        font-size: 30px;
    }

    .mainPage .mobilePictograms {
        max-width: 120px;
    }

    .mainPage .mobileFooter {
        height: 210px;
    }

    .mainPage .mobileIcons {
        max-width: 40px;
    }

    .mainPage .mobileIcons img {
        width: 35px;
        height: 35px;
    }

    .mainPage .content .carImage {
        margin-left: 10px;
    }

    .mainPage .textHolder .textItem {
        margin-left: 10px;
        margin-bottom: 45px;
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 20px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 30px;
    }

    .mainPage .content .prizes {
        top: 627px;
        max-width: 65%;
    }

    .mainPage .prizes>img:first-child {
        max-width: 375px;
    }

    .soonStarting {
        height: calc(100% - 448px);
    }

    .soonMain {
        margin-top: 100px;
        font-size: 100px;
    }

    .soonText {
        display: none;
    }

    .soonTextMobile {
        display: flex;
    }
    .soonStarting .final-text {
        font-size: 30px;
    }

    .soonStarting .final-center {
        font-size: 50px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

@media (max-width: 600px) {
    .mainPage .mobileMainTitle {
        font-size: 20px;
    }

    .mainPage .content {
        padding-top: 238px;
    }

    .mainPage .mobilePictograms {
        max-width: 80px;
    }

    .mainPage .mobileFooter {
        height: 150px;
    }



    .mainPage .content .prizes {
        top: 567px;
        max-width: 60%;
    }

    .mainPage .prizes>img:first-child {
        max-width: 100%;
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 17px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 26px;
    }

    .soonStarting {
        top: 165px;
        height: calc(100% - 315px);
    }

    .soonMain {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 25px;
    }

    .soonTextMobile {
        font-size: 24px;
    }
}

@media (max-width: 460px) {
    .mainPage .content .prizes {
        top: 547px;
        max-width: 55%;
    }

    .mainPage .textHolder .textItem {
        margin-bottom: 30px;
    }

    .mainPage .textHolder .textItem>div:first-child {
        font-size: 14px;
    }

    .mainPage .textHolder .textItem>div:last-child {
        font-size: 20px;
    }

    .mainPage .mobileMainTitle {
        font-size: 17px;
    }
}

@media (max-width: 400px) {
    .mainPage .mobilePictograms {
        margin-left: 15px;
    }

    .mainPage .mobileIcons {
        margin-right: 15px;
    }

    .mainPage .mobileMainTitle {
        font-size: 15px;
    }
    .soonStarting .final-text {
        font-size: 25px;
    }

    .soonStarting .final-center {
        font-size: 43px;
    }
}